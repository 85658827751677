export const PATH = {
  DASHBOARD: "/dashboard",
  FINANCE_DASHBOARD: "/financeDashboard",
  INCOME: "/income",
  EXPENSE: "/expense",
  LOAN: "/loan",
  SALARIES: "/salaries",
  ADDSALARY: "/add-salary",
  EDITSALARY: "/edit-salary",

  INVOICE: "/invoices",
  ADD_INVOICE: "/invoice/add",

  PROVIDENTFUND: "/providentfund",
  CASHCATEGORY: "/cashcategory",
  BILLABLEHOURS: "/billablehours",
  EMPLOYEECOMPENSATIONS: "/employeecompensations",
  VIEW_EMPLOYEE_COMPENSATION_DETAILS: "/employeecompensations/view",
  ADDCOMPENSATION: "/employeecompensations/addcompensation",
  MONTHLYEMPLOYEECOMPENSATION: "/monthlyemployeecompensation",
  COMPENSATION: "/monthlyemployeecompensation/monthlycompensation",
  PAY_CODE: "/pay-code",
};
