import Dashboard from "./Dashboard";
import Income from "./Income";
import Expense from "./Expense";
import Invoice from "./Invoice";
import AddInvoice from "./Invoice/Pages/Add";
import Loan from "./Loan";
import Salaries from "./Salaries";
import AddSalary from "./Salaries/addSalary";
import EditSalary from "./Salaries/editSalary";
import ProvidentFund from "./ProvidentFund";
import Compensation from "./Compensation";
import CashCategory from "./CashCategory";
import BillableHours from "./BillableHours";
import EmployeeCompensations from "./EmployeeCompensations";
import MonthlyEmployeeCompensation from "./MonthlyEmployeeCompensation";
import AddCompensation from "./AddCompensation";
import PayCode from "./PayCode";
import FinanceDashboard from "./Dashboard/financeDashboard";
import ViewEmployeeCompensationDetails from "./MonthlyEmployeeCompensation/ViewDetails";

export const WEB_PAGES = {
  EDITSALARY: EditSalary,
  ADDSALARY: AddSalary,
  LOAN: Loan,
  SALARIES: Salaries,
  EXPENSE: Expense,
  INCOME: Income,
  DASHBOARD: Dashboard,
  INVOICE: Invoice,
  ADD_INVOICE: AddInvoice,
  COMPENSATION: Compensation,
  PAY_CODE: PayCode,
  PROVIDENTFUND: ProvidentFund,
  CASHCATEGORY: CashCategory,
  BILLABLEHOURS: BillableHours,
  EMPLOYEECOMPENSATIONS: EmployeeCompensations,
  MONTHLYEMPLOYEECOMPENSATION: MonthlyEmployeeCompensation,
  VIEW_EMPLOYEE_COMPENSATION_DETAILS: ViewEmployeeCompensationDetails,
  ADDCOMPENSATION: AddCompensation,
  FINANCEDASHBOARD: FinanceDashboard,
};
