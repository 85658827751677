import React, { useState } from "react";
import { Card, Row, Col, Dropdown } from "react-bootstrap";
import Chart from "react-apexcharts";
import {
  FaCaretUp,
  FaCaretDown,
  FaChevronRight,
  FaChevronDown,
} from "react-icons/fa";
import { ReactSelectSm } from "../../genericComponents";
import ReactApexChart from "react-apexcharts";
import {
  getCurrentMonthInSelect,
  getMonthsSelectList,
} from "../../../_metronic/_helpers/GenericFunctionHelpers";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
import { BiTrendingDown, BiTrendingUp } from "react-icons/bi";
highcharts3d(Highcharts);

const FinanceDashboard = () => {
  let dangerColor = "#E54545";
  let primaryColor = "#00F38D";
  let darkblue = "#165BAA";
  let successColor = "#26E7A6";
  // let goldColor = "#F2A81F"
  const dashboardTiles_NetProfit_Chart = {
    series: [40],
    options: {
      chart: {
        // height: 450,
        type: "radialBar",
        sparkline: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 1400,
          options: {
            chart: {
              width: 300,
              height: 230,
            },
          },
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 200,
              height: 240,
            },
          },
        },
        {
          breakpoint: 1024,
          options: {
            chart: {
              width: 400,
              height: 300,
            },
          },
        },
        {
          breakpoint: 768,
          options: {
            chart: {
              width: 300,
              height: 410,
            },
          },
        },
      ],
      colors: ["#009EFF"],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "60%",
            background: "white",
          },
          track: {
            background: "#035ECA",
            strokeWidth: "60%",
            margin: 2, // margin is in pixels
            dropShadow: {
              enabled: false,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 0,
              show: false,
              color: "black",
              fontSize: "13px",
            },
            value: {
              color: "#111",
              fontSize: "30px",
              show: true,
              offsetY: 10,
            },
          },
        },
      },
      labels: ["Net Profit Margin"],
    },
  };

  const income_expense_chart = {
    // colors:["red","black","yellow"],
    series: [
      {
        name: "Income Flow",
        color: "#009EFF",
        type: "bar",
        data: [5000, 4500, 4800, 4400, 5100, 6000],
      },
      {
        name: "Expenses Flow",
        color: "#00F38D",
        type: "bar",
        data: [-3000, -4000, -2000, -2500, -2900, -3000],
      },
      {
        name: "Net Profit",
        color: "#F0D15F",
        type: "line",
        data: [1000, -2000, 1400, 1800, -1000, 1000],
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          // colors: {
          //     ranges: [{
          //         from: -100,
          //         to: -46,
          //         color: '#F15B46'
          //     }, {
          //         from: -45,
          //         to: 0,
          //         color: '#00E396'
          //     }]
          // },
          columnWidth: "40%",
        },
      },
      fill: {
        opacity: 1.0,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1, 3],
        curve: "straight",
      },
      yaxis: {
        labels: {
          formatter: function(y) {
            return y.toFixed(2);
          },
        },
      },

      xaxis: {
        // type: 'datetime',
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        labels: {
          rotate: -90,
        },
      },
    },
  };

  let statement_data = [
    {
      x: ["Total", "Income"],
      y: [0, 4800],
    },
    {
      x: ["Cost", "of Goods"],
      y: [4800, 3400],
    },
    {
      x: ["Gross", "Profit"],
      y: [3000, 6000],
    },
    {
      x: ["Total", "Operating", "Expenses"],
      y: [6000, 4500],
    },
    {
      x: ["Operating", "Profit (EBIT)"],
      y: [4500, 6000],
    },
    {
      x: ["Taxes"],
      y: [6000, 5000],
    },
    {
      x: ["Net", "Profit"],
      y: [0, 6023],
    },
  ];

  const statement_chart = {
    series: [
      {
        data: statement_data,
      },
    ],
    options: {
      chart: {
        type: "rangeBar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -30,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          colors: ["#304758"],
        },
      },
      colors: [
        function({ dataPointIndex }) {
          console.log("dataPointIndex", dataPointIndex);
          if (
            dataPointIndex === 0 ||
            dataPointIndex === statement_data.length - 1
          )
            return "#009EFF";
          if (
            statement_data[dataPointIndex].y[0] >
            statement_data[dataPointIndex].y[1]
          ) {
            return dangerColor;
          }
          if (
            statement_data[dataPointIndex].y[2] <
            statement_data[dataPointIndex].y[3]
          ) {
            return darkblue;
          } else {
            return primaryColor;
          }
        },
      ],
      fill: {
        opacity: 1,
      },
    },
    xaxis: {
      labels: {
        rotate: 0,
      },
    },
  };

  const operating_profit_overTime_chart = {
    series: [
      {
        name: "Revenue",
        type: "column",
        data: [8, 6, 7, 8, 9, 10, 7, 8, 7, 6, 9],
      },
      {
        name: "Expenses",
        type: "column",
        data: [5, 4, 5, 5, 7, 8, 4, 6, 5, 5, 6],
      },
      {
        name: "Gross Profit $",
        type: "line",
        data: [
          14.2,
          30.5,
          34.7,
          35.2,
          45.1,
          37.2,
          39.1,
          40.3,
          32.9,
          28.6,
          39.7,
        ],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 2, 5],
        curve: "straight",
        dashArray: [0, 0, 2],
      },
      plotOptions: {
        bar: {
          columnWidth: "70%",
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2],
        formatter: function(val, opts) {
          return val + "%";
        },
      },
      fill: {
        opacity: [1, 1, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      markers: {
        size: 5,
      },
      yaxis: [
        {
          seriesName: "Revenue",
          axisTicks: {
            show: true,
          },
        },
        {
          seriesName: "Expenses",
          opposite: true,
          enabled: false,
          show: false,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#66DA26",
          },
          labels: {
            style: {
              colors: "#66DA26",
            },
          },
          title: {
            text: "Hired Employee",
            style: {
              color: "#66DA26",
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          seriesName: "Gross Profit $",
          opposite: true,
          enabled: false,
          show: false,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#546E7A",
          },
          labels: {
            style: {
              colors: "#546E7A",
            },
          },
        },
      ],
      colors: [successColor, dangerColor, "black"],
      tooltip: {
        shared: false,
        intersect: false,
        y: {
          formatter: function(y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " points";
            }
            return y;
          },
        },
      },
    },
  };
  const state = {
    series: [60, 20, 15, 8, 14, 10],
    options: {
      chart: {
        // width: 380,
        type: "pie",
      },
      colors: [
        "#52E2A6",
        "#37C1B1",
        "#F0D15F",
        "#F4945E",
        "#8A7732",
        "#F45EC1",
      ],
      labels: [
        "Health Care",
        "General 0.7%",
        "Internal Management 6.2%",
        "Real Estate 8.5%",
        "Social Messenger 12.8%",
        "IT ERP Solution 26.8%",
      ],

      // responsive: [
      //   {
      //     breakpoint: 1920,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 340 + "%",
      //       },
      //     },
      //   },
      //   {
      //     breakpoint: 1640,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 320 + "%",
      //       },
      //     },
      //   },

      //   {
      //     breakpoint: 1281,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 320 + "%",
      //       },
      //     },
      //   },
      //   {
      //     breakpoint: 1024,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 320 + "%",
      //       },
      //     },
      //   },
      //   {
      //     breakpoint: 768,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 220 + "%",
      //       },
      //     },
      //   },
      //   {
      //     breakpoint: 500,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 420 + "%",
      //       },
      //     },
      //   },
      //   {
      //     breakpoint: 425,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 520 + "%",
      //       },
      //     },
      //   },
      // ],

      dataLabels: {
        enabled: false,
      },
    },
  };
  const QAExpenses = {
    series: [70, 5, 8, 8, 14, 10, 10, 5, 6, 9, 10],
    options: {
      chart: {
        // width: 380,
        type: "pie",
      },
      colors: [
        "#00F38D",
        "#009EFF",
        "#F0D15F",
        "#B25EF4",
        "#FEE1BF",
        "#F45EC1",
        "#975EF4",
        "#F4945E",
        "#8A7732",
        "#5EE2F4",
        "#37C1B1",
      ],
      labels: [
        "Salaries & Benefits",
        "Other Expenses",
        "Financial Cost",
        "Trade License",
        "Communication",
        "Utilities",
        "Vehicle Cost",
        "Repair & Maintenance",
        "Insurance Expense",
        "Advertising",
        "Rent Expenses",
      ],

      // responsive: [
      //   {
      //     breakpoint: 1920,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 340 + "%",
      //       },
      //     },
      //   },
      //   {
      //     breakpoint: 1640,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 320 + "%",
      //       },
      //     },
      //   },

      //   {
      //     breakpoint: 1281,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 320 + "%",
      //       },
      //     },
      //   },
      //   {
      //     breakpoint: 1024,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 320 + "%",
      //       },
      //     },
      //   },
      //   {
      //     breakpoint: 768,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 220 + "%",
      //       },
      //     },
      //   },
      //   {
      //     breakpoint: 500,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 420 + "%",
      //       },
      //     },
      //   },
      //   {
      //     breakpoint: 425,
      //     options: {
      //       chart: {
      //         width: (9 / 16) * 520 + "%",
      //       },
      //     },
      //   },
      // ],

      dataLabels: {
        enabled: false,
      },
    },
  };
  const LastYearAcitivty_Graph = {
    series: [
      {
        name: "Client Budget",
        type: "column",
        data: [2, 1, 5, 7, 2, 1],
      },
      {
        name: "Proposed Budget",
        type: "column",
        data: [1, 1, 2, 3, 1, 0],
      },
    ],
    options: {
      series: {
        pointWidth: 10,
      },
      chart: {
        height: 350,
        type: "line",
        // stacked: false
        toolbar: {
          show: false,
        },
        events: {
          mounted: (chart) => {
            chart.windowResizeHandler();
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1, 1],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: [
        {
          seriesName: "Active",

          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "transparent",
          },
          labels: {
            style: {
              colors: "#000000",
            },
          },
          title: {
            text: "Proposed Budget",
            style: {
              color: "#000000",
            },
          },
        },
        {
          seriesName: "Hire",
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#000000",
          },
          labels: {
            style: {
              colors: "#000000",
            },
          },
          title: {
            text: "Clients Budget",
            style: {
              color: "#000000",
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          seriesName: "Leave",
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#000000",
          },
          labels: {
            style: {
              colors: "#000000",
            },
          },
          title: {
            text: "Conversion Probability",
            style: {
              color: "#000000",
            },
          },
        },
      ],
      colors: ["#009EFF", "#00F38D"],
      markers: {
        size: 4,
        colors: undefined,
        strokeColors: "#fff",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        show: true,
        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 3,
        },
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60,
        },
      },
      legend: {
        horizontalAlign: "center",
        // offsetX: 40
      },
    },
  };
  const funnel_Chart = {
    colors: ["#009EFF", "#00F38D", "#F0D15F"],
    chart: {
      height: (9 / 16) * 96 + "%",
    },
    title: {
      text: null,
    },
    credits: { enabled: false },
    xAxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      crosshair: true,
    },
    toolbar: false,
    // toolbar: {
    //   show: false,
    // },
    exporting: { enabled: false },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        type: "column",
        name: "Revenue",
        data: [40, 70, 50, 40, 20, 50, 10],
      },
      {
        type: "spline",
        name: "Expenses",
        data: [70, 20, 50, 40, 50, 80, 30],
      },
      {
        type: "column",
        name: "Gross Profit",
        data: [70, 20, 50, 40, 50, 80, 30],
      },
    ],
  };
  const netProfit_overTime_chart = {
    series: [
      {
        name: "Net Profit",
        type: "bar",
        data: [10, -13, 14, -12, 18, -16],
      },
      {
        name: "Net Profit %",
        type: "line",
        data: [
          -6.38,
          -23.86,
          -22.86,
          -40.67,
          15.8,
          -5.86,
          // 16.9,
          // 10.12,
          // -5.07,
          // 2.89,
          // 5.45,
          // -4.34,
        ],
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: 0,
                to: 100000,
                color: "#009EFF",
              },
              {
                from: -10000,
                to: 0,
                color: "#F0D15F",
              },
            ],
          },
          columnWidth: "80%",
        },
      },
      dataLabels: {
        enabled: false,
        enabledOnSeries: [1],
        formatter: function(val, opts) {
          return val / 2 + "%";
        },
      },
      stroke: {
        width: [0, 2],
        curve: "straight",
        // dashArray: [0, 2],
      },
      colors: ["#009EFF", "#00D179"],
      markers: {
        size: 0,
      },
      yaxis:
        // {
        //     // title: {
        //     //     text: 'Growth',
        //     // },
        //     labels: {
        //         formatter: function (y) {
        //             return y.toFixed(0) + "m";
        //         }
        //     }
        // },
        [
          {
            seriesName: "Net Profit",
            axisTicks: {
              show: true,
            },
            // axisBorder: {
            //     show: true,
            //     color: '#008FFB'
            // },
            // labels: {
            //     style: {
            //         colors: '#008FFB',
            //     },
            // },
            // title: {
            //     text: "Active Employee",
            //     style: {
            //         color: '#008FFB',
            //     }
            // }
          },
          {
            seriesName: "Net Profit %",
            opposite: true,
            enabled: false,
            show: false,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#66DA26",
            },
            labels: {
              style: {
                colors: "#66DA26",
              },
            },
            title: {
              text: "Hired Employee",
              style: {
                color: "#66DA26",
              },
            },
            tooltip: {
              enabled: true,
            },
          },
        ],
      xaxis: {
        // type: 'datetime',
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          // "Jul",
          // "Aug",
          // "Sep",
          // "Oct",
          // "Nov",
          // "Dec",
        ],
        labels: {
          rotate: -90,
        },
      },
    },
  };

  const Time_Duration_Options = [
    { value: "Last12Months", label: "Last 12 Months" },
    { value: "2021", label: "Last Year" },
    { value: "2020", label: "2020" },
    { value: "2019", label: "2019" },
    { value: "2018", label: "2018" },
  ];

  const project_revenue_chart = {
    chart: {
      type: "pie",
      options3d: {
        enabled: true,
        alpha: 45,
        beta: 0,
      },
      height: "364",
    },
    title: {
      text: "",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        depth: 35,
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    series: [
      {
        type: "pie",
        name: "Project Revenue",
        data: [
          ["Health Care", 45.0],
          ["IT ERP Solution", 26.8],
          ["Social Messenger", 12.8],
          ["Real State", 8.5],
          ["Internal Management", 6.2],
          ["General", 0.7],
        ],
      },
    ],
  };

  const g_And_a_options = {
    chart: {
      type: "pie",
      options3d: {
        enabled: true,
        alpha: 45,
      },
      height: "364",
    },
    title: {
      text: "",
    },
    plotOptions: {
      pie: {
        innerSize: 80,
        depth: 45,
      },
    },
    series: [
      {
        name: "Expenses",
        data: [
          ["Salaries And Benefits", 50000],
          ["Rent Expenses", 20000],
          ["Advertising", 10000],
          ["Insurance Expenses", 5000],
          ["Repair and Maintenance", 5000],
          ["Vehicle Cost", 3000],
          ["Utilities", 8000],
          ["Communication", 2000],
          ["Trade License", 1000],
          ["Financial Cost", 3000],
          ["Other Expenses", 6000],
        ],
      },
    ],
  };

  const warm_leads_options = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Warm Leads",
    },
    xAxis: {
      categories: [
        "akshay  Makar",
        "kaylee  Philbrick",
        "victor  Wang",
        "ravleen  Isher",
        "mykhailo  Gusiev",
        "geeta  Powani",
        "ryota  Ichioka",
        "yama Habibi",
        "dana  Meromsky",
        "connie  Ho",
      ],
      title: {
        text: "Leads",
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: "Conversion Probabilty (%)",
        align: "high",
      },
      labels: {
        overflow: "justify",
        format: "{text}%",
      },
      tickInterval: 20,
    },
    tooltip: {
      formatter: function() {
        return (
          "<b>Expected Closure: </b>" +
          this.point.date +
          "<br>" +
          "<b>Agent: </b>" +
          this.point.agent +
          "<br>" +
          "<b>Project Category: </b>" +
          this.point.projectCategory
        );
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          x: -10000,
          y: -12,
        },
        //enabled: true,
        pointWidth: 10,
        //inside: true
      },
      bar: {
        colorByPoint: true,
        dataLabels: {
          enabled: true,
          formatter: function() {
            return (
              '<div style="font-weight: normal;">' +
              "Client Budget: $" +
              this.point.clientBudget +
              "k" +
              " | Proposed Budget: $" +
              this.point.proposedBudget +
              "k" +
              "</div>"
            );
          },
        },
      },
      //showInLegend: true
    },
    //legend: {
    //    layout: 'vertical',
    //    align: 'right',
    //    verticalAlign: 'top',
    //    x: -40,
    //    y: 80,
    //    floating: true,
    //    borderWidth: 1,
    //    backgroundColor:
    //        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
    //    shadow: true
    //},
    credits: {
      enabled: false,
    },
    series: [
      {
        showInLegend: false,
        name: "Conversion Probability",
        data: [
          {
            id: 216,
            y: 92,
            name: "Website",
            date: "2021-11-16T00:00:00",
            clientBudget: 25000,
            proposedBudget: 0,
            color: "#CF841E",
            projectCategory: "Other",
            agent: "umartestuser17 17",
          },
          {
            id: 213,
            y: 90,
            name: "LinkedIn",
            date: "2021-11-16T00:00:00",
            clientBudget: 0,
            proposedBudget: 0,
            color: "#0077B5",
            projectCategory: "HealthCare",
            agent: "umartestuser17 17",
          },
          {
            id: 220,
            y: 85,
            name: "Website",
            date: "2021-11-16T00:00:00",
            clientBudget: 0,
            proposedBudget: 0,
            color: "#CF841E",
            projectCategory: "AI",
            agent: "umartestuser17 17",
          },
          {
            id: 217,
            y: 82,
            name: "Email",
            date: "2021-11-16T00:00:00",
            clientBudget: 250,
            proposedBudget: 0,
            color: "#FDB501",
            projectCategory: "Other",
            agent: "umartestuser17 17",
          },
          {
            id: 211,
            y: 80,
            name: "Upwork",
            date: "2021-11-16T00:00:00",
            clientBudget: 0,
            proposedBudget: 0,
            color: "#55BF55",
            projectCategory: "Other",
            agent: "umartestuser17 17",
          },
          {
            id: 218,
            y: 76,
            name: "Email",
            date: "2021-11-16T00:00:00",
            clientBudget: 0,
            proposedBudget: 0,
            color: "#FDB501",
            projectCategory: "Other",
            agent: "umartestuser17 17",
          },
          {
            id: 214,
            y: 75,
            name: "LinkedIn",
            date: "2021-11-16T00:00:00",
            clientBudget: 0,
            proposedBudget: 0,
            color: "#0077B5",
            projectCategory: "Other",
            agent: "umartestuser17 17",
          },
          {
            id: 219,
            y: 69,
            name: "Social Media",
            date: "2021-11-16T00:00:00",
            clientBudget: 250,
            proposedBudget: 3000,
            color: "#00BBBD",
            projectCategory: "Other",
            agent: "umartestuser17 17",
          },
          {
            id: 212,
            y: 65,
            name: "LinkedIn",
            date: "2021-11-16T00:00:00",
            clientBudget: 160,
            proposedBudget: 6000,
            color: "#0077B5",
            projectCategory: "Other",
            agent: "umartestuser17 17",
          },
          {
            id: 215,
            y: 62,
            name: "Social Media",
            date: "2021-11-16T00:00:00",
            clientBudget: 0,
            proposedBudget: 0,
            color: "#00BBBD",
            projectCategory: "AI",
            agent: "umartestuser17 17",
          },
        ],
        // data: [
        //     {
        //         id: 2763,
        //         y: 10,
        //         x: 'Asad',
        //         name: 'Upwork',
        //         date: '2021-10-10',
        //         clientBudget: 30,
        //         proposedBudget: 40,
        //         color: '#55BF55',
        //         projectCategory: 'Healthcare',
        //         agent: 'Asad'
        //     },
        //     {
        //         id: 519,
        //         y: 20,
        //         name: 'Linkedin',
        //         date: '2021-10-15',
        //         clientBudget: 25,
        //         proposedBudget: 40,
        //         color: '#0077B5',
        //         projectCategory: 'IT ERP Solutions',
        //         agent: 'Ahmed sultan'
        //     }, {
        //         id: 275,
        //         y: 40,
        //         name: 'Email',
        //         date: '2021-10-17',
        //         clientBudget: 80,
        //         proposedBudget: 82,
        //         color: '#FDB501',
        //         projectCategory: 'Internal Management',
        //         agent: 'Umar Ilyas'
        //     }, {
        //         id: 263,
        //         y: 50,
        //         name: 'Social Media',
        //         date: '2021-10-23',
        //         clientBudget: 92,
        //         proposedBudget: 100,
        //         color: '#00BBBD',
        //         projectCategory: 'Healthcare',
        //         agent: 'Ayesha'
        //     },
        //     {
        //         id: 263,
        //         y: 60,
        //         name: 'webiste',
        //         date: '2021-10-23',
        //         clientBudget: 110,
        //         proposedBudget: 120,
        //         color: '#CF841E',
        //         projectCategory: 'Real State',
        //         agent: 'Asad'
        //     }
        // ]
      },
    ],
  };

  const goToPage = (path) => {
    window.location.href = path;
  };
  return (
    <div className="row">
      {/* Start Page Header (Title and Other Option) */}
      <div className="col-12 primary-font-regular font-weight-700 mb-8">
        <div className="row">
          <div className="col-6 col-md">
            <Dropdown className="dasboard-dropdown">
              <Dropdown.Toggle className="ceo-dropdown">
                Chief Financial Officer <FaChevronDown size={15} />
              </Dropdown.Toggle>
              <Dropdown.Menu className=" dropdown-menu shadow bg-white dropdown-menu-activ-color">
                <Dropdown.Item
                  onClick={() =>
                    goToPage("https://pmsdev.visioncollab.com/dashboard/ceo")
                  }
                >
                  {"Chief Executive Officer"}
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={() =>
                    goToPage("https://stgvisioncollab.azurewebsites.net/dashboard/employee")
                  }
                >
                  {"DASHBOARD EMPLOYEE"}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    goToPage("https://stgvisioncollab.azurewebsites.net/dashboard/department")
                  }
                >
                  {"DASHBOARD DEPARTMENT"}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    goToPage("https://stgvisioncollab.azurewebsites.net/dashboard/sales")
                  }
                >
                  {"DASHBOARD SALES"}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    goToPage("https://stgvisioncollab.azurewebsites.net/dashboard/goals")
                  }
                >
                  {"DASHBOARD GOALS"}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    goToPage("https://stgvisioncollab.azurewebsites.net/dashboard/teams")
                  }
                >
                  {"DASHBOARD TEAMS"}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    goToPage("https://stgvisioncollab.azurewebsites.net/dashboard/performance")
                  }
                >
                  {"DASHBOARD PERFORMANCE"}
                </Dropdown.Item> */}

                <Dropdown.Item
                  onClick={() =>
                    goToPage(
                      "https://pmsdev.visioncollab.com/dashboard/business-development"
                    )
                  }
                >
                  {"Business Development Officer"}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    goToPage("https://hrm.visioncollab.com/app/Dashboard")
                  }
                >
                  {"Human Resource Management"}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    goToPage(
                      "https://hrm.visioncollab.com/app/recruitment/Dashboard"
                    )
                  }
                >
                  {"Recruitment"}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    goToPage("https://pmsdev.visioncollab.com/dashboard/pm")
                  }
                >
                  {"Project Management"}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    goToPage(
                      "https://businessmanagement.visioncollab.com/augmentation-dashboard"
                    )
                  }
                >
                  {"Marketing Management"}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      {/* <div className="col-lg-12 mb-3">
        <h2 className="font-weight-boldest">Finance Dashboard</h2>
      </div> */}
      {/* End Page Header (Title and Other Option) */}

      <Col lg="4">
        <Row>
          <Col>
            <div class="card card-custom gutter-b ">
              <div class="card-body d-flex flex-column p-3">
                <div class="flex-grow-1 d-flex flex-column justify-content-between">
                  <div className="buisness-dashboard-tile ceo-dashboard-tile">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="">
                        <span className="h3 generic-card-title">
                          Total Income
                        </span>
                        <h4 className=" d-flex align-items-center pt-1">
                          $95.6M
                        </h4>
                        <div className="d-flex align-items-center pt-3 flex-wrap">
                          <span className="text-danger font-size mt-1 ml-1">
                            <BiTrendingDown className="mb-0 " /> 23.95%
                          </span>
                          <small className="ml-2">vs previous month</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div class="card card-custom gutter-b ">
              <div class="card-body d-flex flex-column p-3">
                <div class="flex-grow-1 d-flex flex-column justify-content-between">
                  <div className="buisness-dashboard-tile ceo-dashboard-tile">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="">
                        <span className="h3 generic-card-title">
                          Total Expenses
                        </span>
                        <h4 className=" d-flex align-items-center pt-1">
                          $95.6M
                        </h4>
                        <div className="d-flex align-items-center pt-3 flex-wrap">
                          <span className="text-danger font-size mt-1 ml-1">
                            <BiTrendingDown className="mb-0 " /> 23.95%
                          </span>
                          <small className="ml-2">vs previous month</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div class="card card-custom gutter-b ">
              <div class="card-body d-flex flex-column p-3">
                <div class="flex-grow-1 d-flex flex-column justify-content-between">
                  <div className="buisness-dashboard-tile ceo-dashboard-tile">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="">
                        <span className="h3 generic-card-title">
                          Net Profit
                        </span>
                        <h4 className=" d-flex align-items-center pt-1">
                          $45.6M
                        </h4>
                        <div className="d-flex align-items-center pt-3 flex-wrap">
                          <span className="text-danger font-size mt-1 ml-1">
                            <BiTrendingDown className="mb-0 " /> 23.95%
                          </span>
                          <small className="ml-2">vs previous month</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div class="card card-custom gutter-b ">
              <div class="card-body d-flex flex-column p-3">
                <div class="flex-grow-1 d-flex flex-column justify-content-between">
                  <div className="buisness-dashboard-tile ceo-dashboard-tile">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="">
                        <span className="h3 generic-card-title">
                          Cash at End of Month
                        </span>
                        <h4 className=" d-flex align-items-center pt-1">
                          $95.6M
                        </h4>
                        <div className="d-flex align-items-center pt-3 flex-wrap">
                          <span className="text-danger font-size mt-1 ml-1">
                            <BiTrendingDown className="mb-0 " /> 23.95%
                          </span>
                          <small className="ml-2">vs previous month</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>

      <Col lg="4">
        <Row>
          <Col>
            <div class="card card-custom gutter-b ">
              <div class="card-body d-flex flex-column p-3">
                <div class="flex-grow-1 d-flex flex-column justify-content-between">
                  <div className="buisness-dashboard-tile ceo-dashboard-tile ">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="">
                        <span className="h3 generic-card-title">
                          Accounts Receivable
                        </span>
                        <h4 className=" d-flex align-items-center pt-1">
                          $19.6M
                        </h4>
                        <div className="d-flex align-items-center pt-3 flex-wrap">
                          <span className="aroow-up-font-color font-size mt-1 ml-1">
                            <BiTrendingUp className="mb-0" /> 23.95 %
                          </span>
                          <small className="ml-2 font-size">
                            vs previous month
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div class="card card-custom gutter-b ">
              <div class="card-body d-flex flex-column p-3">
                <div class="flex-grow-1 d-flex flex-column justify-content-between">
                  <div className="buisness-dashboard-tile ceo-dashboard-tile ">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="">
                        <span className="h3 generic-card-title">
                          Accounts Payable
                        </span>
                        <h4 className=" d-flex align-items-center pt-1">
                          $15.0M
                        </h4>
                        <div className="d-flex align-items-center pt-3 flex-wrap">
                          <span className="aroow-up-font-color font-size mt-1 ml-1">
                            <BiTrendingUp className="mb-0" /> 23.95 %
                          </span>
                          <small className="ml-2 font-size">
                            vs previous month
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div class="card card-custom gutter-b ">
              <div class="card-body d-flex flex-column p-3">
                <div class="flex-grow-1 d-flex flex-column justify-content-between">
                  <div className="buisness-dashboard-tile ceo-dashboard-tile">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="">
                        <span className="h3 generic-card-title">
                          $ of Income Budget
                        </span>
                        <h4 className=" d-flex align-items-center pt-1">
                          $45.6M
                        </h4>
                        <div className="d-flex align-items-center pt-3 flex-wrap">
                          <span className="text-danger font-size mt-1 ml-1">
                            <BiTrendingDown className="mb-0 " /> 23.95%
                          </span>
                          <small className="ml-2">vs previous month</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div class="card card-custom gutter-b ">
              <div class="card-body d-flex flex-column p-3">
                <div class="flex-grow-1 d-flex flex-column justify-content-between">
                  <div className="buisness-dashboard-tile ceo-dashboard-tile">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="">
                        <span className="h3 generic-card-title">
                          $ of Income Budget
                        </span>
                        <h4 className=" d-flex align-items-center pt-1">
                          $95.6M
                        </h4>
                        <div className="d-flex align-items-center pt-3 flex-wrap">
                          <span className="text-danger font-size mt-1 ml-1">
                            <BiTrendingDown className="mb-0 " /> 23.95%
                          </span>
                          <small className="ml-2">vs previous month</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col lg="4" sm="12" className="align-self-center ">
        <div class="card-custom card  gutter-b ">
          <div class="card-body d-flex flex-column px-3 py-2">
            <div class="flex-grow-1 d-flex flex-column justify-content-between">
              <div className="buisness-dashboard-tile ceo-dashboard-tile">
                <div className="d-flex align-items-center justify-content-start ">
                  <div className="mt-1">
                    <span className="h3 generic-card-title m-1 pt-1">
                      Net Profit Margin
                    </span>{" "}
                    <Chart
                      options={dashboardTiles_NetProfit_Chart.options}
                      series={dashboardTiles_NetProfit_Chart.series}
                      type="radialBar"
                      height={225}
                    />
                  </div>
                  <div>
                    {" "}
                    <span className="border-style-net-profit">00</span>
                    <span className="h3 net-profit-text">
                      Net Profit Margin
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col lg="6" className="mb-5">
        <div className="card dashboard-card_primary">
          <Card>
            <div className="card-header">
              <div className="row justify-content-between align-items-center">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6">
                  <span className="h3 generic-card-title">
                    Income & Expenses
                  </span>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-1 mt-sm-0">
                  <ReactSelectSm
                    options={Time_Duration_Options}
                    defaultValue={[Time_Duration_Options[0]]}
                  />
                </div>
              </div>
            </div>
            <Card.Body className="py-5">
              <Chart
                series={income_expense_chart.series}
                options={income_expense_chart.options}
                type="line"
                height={350}
              />
            </Card.Body>
          </Card>
        </div>
      </Col>
      <Col lg="6" className="mb-5">
        <div className="card dashboard-card_primary">
          <Card>
            <div className="card-header">
              <div className="row justify-content-between align-items-center">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6">
                  <span className="h3 generic-card-title">Statement</span>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-1 mt-sm-0">
                  <ReactSelectSm
                    options={getMonthsSelectList()}
                    defaultValue={getCurrentMonthInSelect()}
                  />
                </div>
              </div>
            </div>
            <Card.Body className="py-5 ">
              <Chart
                series={statement_chart.series}
                options={statement_chart.options}
                type="rangeBar"
                height={350}
              />
            </Card.Body>
          </Card>
        </div>
      </Col>
      <Col lg="6" className="mb-5">
        <div className="card dashboard-card_primary">
          <Card>
            <div className="card-header">
              <div className="row justify-content-between align-items-center">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6">
                  <span className="h3 generic-card-title">
                    {" "}
                    Operating Profit Over Time
                  </span>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-1 mt-sm-0">
                  <ReactSelectSm
                    options={Time_Duration_Options}
                    defaultValue={[Time_Duration_Options[0]]}
                  />
                </div>
              </div>
            </div>
            <Card.Body className="py-5 ">
              <HighchartsReact
                highcharts={Highcharts}
                options={funnel_Chart}
                isPureConfig={true}
              />
            </Card.Body>
          </Card>
        </div>
      </Col>
      <Col lg="6" className="mb-5">
        <div className="card dashboard-card_primary">
          <Card>
            <div className="card-header">
              <div className="row justify-content-between align-items-center">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6">
                  <span className="h3 generic-card-title">
                    Net Profit Over Time
                  </span>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-1 mt-sm-0">
                  <ReactSelectSm
                    options={Time_Duration_Options}
                    defaultValue={[Time_Duration_Options[0]]}
                  />
                </div>
              </div>
            </div>
            <Card.Body className="py-5 ">
              <Chart
                series={netProfit_overTime_chart.series}
                options={netProfit_overTime_chart.options}
                // type="line"
                width={(9 / 16) * 148 + "%"}
              />
            </Card.Body>
          </Card>
        </div>
      </Col>
      <Col lg="6" className="mb-5">
        <div className="card dashboard-card_primary">
          <Card>
            <div className="card-header">
              <div className="row justify-content-between align-items-center">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6">
                  <span className="h3 generic-card-title">
                    {" "}
                    Revenue Against Each Project (Category Wise)
                  </span>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-1 mt-sm-0">
                  <ReactSelectSm
                    options={Time_Duration_Options}
                    defaultValue={[Time_Duration_Options[0]]}
                  />
                </div>
              </div>
            </div>
            <Card.Body className="py-5 my-4">
              <ReactApexChart
                options={state.options}
                series={state.series}
                type="pie"
                width={(9 / 16) * 145 + "%"}
              />
            </Card.Body>
          </Card>
        </div>
      </Col>
      <Col lg="6" className="mb-5">
        <div className="card dashboard-card_primary">
          <Card>
            <div className="card-header">
              <div className="row justify-content-between align-items-center">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6">
                  <span className="h3 generic-card-title">G & A Expenses</span>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mt-1 mt-sm-0">
                  <ReactSelectSm
                    options={getMonthsSelectList()}
                    defaultValue={getCurrentMonthInSelect()}
                  />
                </div>
              </div>
            </div>
            <Card.Body className="py-5 ">
              <ReactApexChart
                options={QAExpenses.options}
                series={QAExpenses.series}
                type="pie"
                width={(9 / 16) * 145 + "%"}
              />
            </Card.Body>
          </Card>
        </div>
      </Col>

      <Col xs="12">
        <div className="card dashboard-card_primary">
          <Card>
            <div className="card-header">
              <div className="row justify-content-between align-items-center">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6">
                  <span className="h3 generic-card-title">Warm Leads</span>
                </div>
              </div>
            </div>
            <Card.Body className="py-5 ">
              <Chart
                options={LastYearAcitivty_Graph.options}
                series={LastYearAcitivty_Graph.series}
                type="line"
                height={380}
              />
            </Card.Body>
          </Card>
        </div>
      </Col>
    </div>
  );
};

export default FinanceDashboard;
