import AppLogo from "./VC-Logo.png";
import notificatio1 from "./notification1.png";
import notificatio2 from "./notification2.png";
import notificatio3 from "./notification3.png";
import notificatio4 from "./notification4.png";
import notificatio5 from "./notification5.png";
import XevenLogo from "./xevenlogo.png";
import projectManagementIcon from "./ProjectManagementIcon.png";
import hrManagerIcon from "./HRManagerIcon.png";
import resourceTrackerIcon from "./ResourceTrackerIcon.png";
import financialSystemIcon from "./FinancialSystemIcon.png";
import fileCloudIcon from "./FileCloudIcon.png";
import singOutImg from "./singOutImg.png";

export const IMAGES = {
    APPLOGO: AppLogo,
    XEVENLOGO: XevenLogo,
    NOTIFICATION5: notificatio5,
    NOTIFICATION4: notificatio4,
    NOTIFICATION3: notificatio3,
    NOTIFICATION2: notificatio2,
    NOTIFICATION1: notificatio1,
    PROJECT_MANAGEMENT_ICON: projectManagementIcon,
    HRMANAGER_ICON: hrManagerIcon,
    RESOURCE_TRACKER_ICON: resourceTrackerIcon,
    FINANCIAL_SYSTEM_ICON: financialSystemIcon,
    FILE_CLOUD_ICON: fileCloudIcon,
    SIGNOUTIMG: singOutImg,
}